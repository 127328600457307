<template>
  <app-layout>
    <div class="flex flex-col p-4 items-center">
      <div class="w-full px-12">
        <div class="flex flex-col space-y-6 text-center">
          <el-tabs type="border-card">
            <el-tab-pane label="Finanzas">
              <Finances />
            </el-tab-pane>
            <el-tab-pane label="Estadisticas">
              <Statistics />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<script>
import Finances from './Finances.vue';
import Statistics from './Statistics.vue';

export default {
  name: "FinancesIndex",
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
    Finances,
    Statistics,
  },
};
</script>
