<template>
    <div>
        <div class="w-full flex flex-row justify-center">
            <div
                class="border border-purple-500 text-xl text-center cursor-pointer"
                :class="{ 'bg-purple-500 text-white': filters.filterType === DateFilterTypes.Week }"
                style="min-width: 100px; width: 100px;"
                @click="updateFilters(DateFilterTypes.Week)">
                Semana
            </div>
            <div
                class="border border-purple-500 text-xl text-center cursor-pointer"
                :class="{ 'bg-purple-500 text-white': filters.filterType === DateFilterTypes.Month }"
                style="min-width: 100px; width: 100px;"
                @click="updateFilters(DateFilterTypes.Month)">
                Mes
            </div>
            <div
                class="border border-purple-500 text-xl text-center cursor-pointer"
                :class="{ 'bg-purple-500 text-white': filters.filterType === DateFilterTypes.Year }"
                style="min-width: 100px; width: 100px;"
                @click="updateFilters(DateFilterTypes.Year)">
                Año
            </div>
        </div>
        <div class="w-full inline-flex">
            <content-card class="w-1/2 m-4" :itemsCentered="false">
                <template v-slot:title>
                    Ventas
                </template>
                <template v-slot:description>
                    Ventas totales por un valor de {{ moneyFormat(sales.total) }}
                </template>
                <chart :options="sales.options" />
            </content-card>
            <content-card class="w-1/2 m-4" :itemsCentered="false">
                <template v-slot:title>
                    Historial de Ventas
                </template>
                <template v-slot:description>
                    <span class="font-bold">Ticket promedio</span> con valor de {{ moneyFormat(tickets.total) }}
                </template>
                <chart :options="tickets.options" />
                <div class="w-full text-right" @click="goto('finances.historical_sales')">
                    <a class="font-bold color-primary">
                        Ver mas
                    </a>
                </div>
            </content-card>
        </div>
        <div class="w-full inline-flex">
            <content-card class="w-1/2 m-4" :itemsCentered="false">
                <template v-slot:title>
                    Gastos Totales
                </template>
                <template v-slot:description>
                    Próximamente Membresia PRO
                </template>
            </content-card>
            <content-card class="w-1/2 m-4" :itemsCentered="false">
                <template v-slot:title>
                    Utilidad
                </template>
                <template v-slot:description>
                    Próximamente Membresia PRO
                </template>
            </content-card>
        </div>
    </div>
</template>
<style>
.highcharts-axis-labels > text {
  text-transform: capitalize;
}
</style>
<script>
import { Chart } from 'highcharts-vue'
import numbers from '@/utils/numbers';
import dates from '@/utils/dates';
import { getLightColor } from '@/utils/colors';
import OrderService from '@/services/OrderService';
import { DateFilterTypes } from '@/constants';

export default {
    name: 'Finances',
    components: {
        ContentCard: () => import('@/components/molecules/ContentCard'),
        Chart,
    },
    mounted() {
        this.load();
    },
    data() {
        return {
            DateFilterTypes,
            filters: {
                filterType: DateFilterTypes.Week,
                startDate: dates.getFirstDayOfWeek(),
                endDate: dates.getLastDayOfWeek(),
            },
            sales: {
                total: 0,
                options: {
                    colors: [
                        '#6EE7B7',
                        '#A5B4FC',
                    ],
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: "pie",
                    },
                    title: {
                        text: null
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>'
                            + `<td style="padding:0"><b>${this.$store.state.account.currencySymbol} {point.y:,.0f}</b></td></tr>`,
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true,
                        },
                    },
                    series: [
                        {
                            name: "Ventas",
                            data: [
                                [`Servicios: [0.00 %, ${this.moneyFormat(0)}]`, 0],
                                [`Productos: [0.00 %, ${this.moneyFormat(0)}]`, 0],
                            ]
                        }
                    ]
                }
            },
            tickets: {
                total: 0,
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: "column",
                    },
                    title: {
                        text: null
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                        showInLegend: false,
                    },
                    xAxis: {
                        categories: [],
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: null,
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>'
                            + `<td style="padding:0"><b>${this.$store.state.account.currencySymbol} {point.y:,.0f}</b></td></tr>`,
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true,
                    },
                    series: [],
                    colors: [],
                },
            },
        };
    },
    methods: {
        updateFilters(filterType) {
          let startDate = this.filters.startDate;
          let endDate = this.filters.endDate;

          if (filterType === DateFilterTypes.Week) {
            startDate = dates.getFirstDayOfWeek();
            endDate = dates.getLastDayOfWeek();
          }
          if (filterType === DateFilterTypes.Month) {
            startDate = dates.getFirstDayOfYear();
            endDate = dates.getLastDayOfYear();
          }
          if (filterType === DateFilterTypes.Year) {
            startDate = dates.toFormattedString(new Date('01/01/2022'));
            endDate = dates.toFormattedString(new Date('12/31/2024'));
          }
          this.filters = {
            filterType,
            startDate,
            endDate,
          };
          this.load();
        },
        async load() {
            const { sales, totalTicket, averageTotal } = await OrderService
                .getFinances({
                    filterType: this.filters.filterType,
                    startDate: dates.toFormattedString(this.filters.startDate),
                    endDate: dates.toFormattedString(this.filters.endDate),
                });
            this.setSalesValues(sales);
            this.setTotalTicketValues(totalTicket);
            this.tickets.total = averageTotal;
        },
        setSalesValues(sales) {
            this.sales.total = sales.services + sales.products;
            this.sales.options.series = [
                {
                    name: "Ventas",
                    data: [
                        [
                            `Servicios | ${this.moneyFormat(sales.services)} | ${numbers.getAverage(sales.services, this.sales.total)}`,
                            sales.services,
                        ],
                        [
                            `Productos | ${this.moneyFormat(sales.products)} | ${numbers.getAverage(sales.products, this.sales.total)}`,
                            sales.products,
                        ],
                    ]
                }
            ];
        },
        setTotalTicketValues(data) {
            const categories = Object.values(data).map((ticket) => ticket.name);
            const totalTickets = [];
            const colors = [];

            categories.forEach((category) => {
                const { total } = data.find((ticket) => ticket.name === category);
                totalTickets.push(total);
                colors.push(getLightColor('123456789'));
            });

            const series = [
                {
                    name: 'Total Ventas',
                    data: totalTickets,
                },
            ];

            this.tickets.options.xAxis.categories = categories;
            this.tickets.options.series = series;
            this.tickets.options.colors = colors;
        },
        moneyFormat(value) {
            const { currencySymbol } = this.$store.state.account;
            return numbers.moneyFormat(value, currencySymbol);
        },
        goto(name) {
            this.$router.push({ name, params: { filters: this.filters } });
        },
    },
}
</script>