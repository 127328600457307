<template>
    <div>
        <h1
        style="text-align: center;"
        class="text-center my-4 text-2xl leading-tight md:text-2xl md:text-left slide-in-bottom-h1"
        >
            <span style="color: rgba(139, 92, 246)" class="font-bold"
                >Estamos trabajando en esta funcionalidad</span
            >
        </h1>
        <img style="padding-left: 33%;" src="@/assets/img/hero.png" alt="Logo" width="70%" />
    </div>
</template>
<script>
export default {
    name: 'Statistics',
}
</script>